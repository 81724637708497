import Main from "../component/home/Main";
import Wrapper from "../component/Wrapper";

function Home(){
    return (
        <Wrapper helmetTitle={"Home"}>
            <Main />
        </Wrapper>
    )
}

export default Home;