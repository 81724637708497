export default {
    home: {
        en: "Home",
        ru: "Дом",
        am: "Տուն",
        pl: "Dom"
    },
    construction: {
        en: "Construction",
        ru: "Строительство",
        am: "Շինարարություն",
        pl: "Budowa"
    },
    employmentAgency: {
        en: "Employment Agency",
        ru: "Трудоустройтсво",
        am: "Զբաղվածություն",
        pl: "Zatrudnienie"
    },
    logistics: {
        en: "Logistics",
        ru: "Логистика",
        am: "Լոգիստիկա",
        pl: "Logistyka"
    },
    taxi:{
        en: "Taxi",
        ru: "Такси",
        am: "Տաքսի",
        pl: "Taxi"
    },
    phone: {
        en: "Phone",
        ru: "Телефон",
        am: "Հեռախոսահամար",
        pl: "Telefon"
    },
    address: {
        en: "Address",
        ru: "Адрес",
        am: "Հասցե",
        pl: "Adres"
    },
    messenger: {
        en: "Messenger",
        ru: "Мессенджер",
        am: "Մեսենջեռ",
        pl: "Komunikator"
    },
    logOut: {
        en: "log-out",
        ru: "Выйти",
        am: "Դուրս գալ",
        pl: "Wyloguj"
    },
    logIn: {
        en: "log-in",
        ru: "Вход",
        am: "Մուտք",
        pl: "Zaloguj sie"
    },

    /* vacancies */
    vacancies: {
        en: "Vacancies",
        ru: "Вакансии",
        am: "Աշխատատեղեր",
        pl: "Wakaty"
    },
    vacanciesPrice: {
        en: "Salary",
        ru: "Зарплата",
        am: "Աշխատավարձ",
        pl: "Wynagrodzenie"
    },
    vacanciesHAW: {
        en: "Hours a Week",
        ru: "Часов в неделю",
        am: "Շաբաթական ժամեր",
        pl: "Godzin w tygodniu"
    },
    vacanciesHour: {
        en: "h",
        ru: "ч",
        am: "ժ",
        pl: "g"
    },
    vacanciesWorkSchedule: {
        en: "Work Schedule",
        ru: "График работы",
        am: "Աշխատանքային գրաֆիկ",
        pl: "Plan pracy"
    },
    vacanciesJoin: {
        en: "More",
        ru: "Подробнее",
        am: "Ավելին",
        pl: "Więcej"
    },
    currentProjects: {
        en: "Current projects",
        ru: "Текущие проекты",
        am: "Ընթացիկ նախագծեր",
        pl: "Obecne projekty"
    },
    completedProjects: {
        en: "Completed projects",
        ru: "Завершенные проекты",
        am: "Ավարտված նախագծեր",
        pl: "Zrealizowane projekty"
    },
    /* offer */
    offer: {
        en: "Offer",
        ru: "Предложения",
        am: "Առաջաչկներ",
        pl: "Oferta"
    },
    offerName: {
        en: "Name",
        ru: "Имя",
        am: "Անուն",
        pl: "Nazwa"
    },
    offerPhone: {
        en: "Phone",
        ru: "Номер",
        am: "Հեռախոսահամար",
        pl: "Telefon"
    },
    offerMessage: {
        en: "Message",
        ru: "Сообщение",
        am: "Հաղորդագրություն ",
        pl: "wiadomość"
    },
    submit:{
        en: "Send",
        ru: "Отправить",
        am: "Ուղղարկել",
        pl: "Wysłać"
    },
    gallery:{
        en: "Gallery",
        ru: "Галерея",
        am: "Տեսադարան",
        pl: "Galeria"
    },
    photo:{
        en: "Photo",
        ru: "Фото",
        am: "Նկարներ",
        pl: "Zdjęcie"
    },
    video:{
        en: "Video",
        ru: "Видео",
        am: "Վիդեո",
        pl: "Wideo"
    },
    contacts:{
        en: "Contacts",
        ru: "Контакты",
        am: "Կոնտակտներ",
        pl: "Łączność"
    },
    application:{
        en: "Application",
        ru: "Заявление",
        am: "Դիմում",
        pl: "Aplikacja"
    },
    packages:{
        en: "Packages",
        ru: "Пакеты",
        am: "Փաթեթներ",
        pl: "Pakiety"
    },
    standard:{
        en: "Standard",
        ru: "Стандартный",
        am: "Ստանդարտ",
        pl: "Standard"
    },
    advanced:{
        en: "Advanced",
        ru: "Продвинутый",
        am: "Ընդլայնված",
        pl: "Zaawansowany"
    },
    premium:{
        en: "Premium",
        ru: "Премиум",
        am: "Պրեմիում",
        pl: "Premia"
    },
    order:{
        en: "ORDER",
        ru: "ЗАКАЗ",
        am: "ՊԱՏՎԵՐ",
        pl: "ZAMÓWIENIE"
    },
    partners:{
        en: "Partners",
        ru: "Партнеры",
        am: "Գործընկերներ",
        pl: "Wzmacniacz"
    },
    history:{
        en: "History",
        ru: "История",
        am: "Պատմություն",
        pl: "Historia"
    },
    calculator:{
        en: "Calculator",
        ru: "Калькулятор",
        am: "Հաշվիչ",
        pl: "Kalkulator"
    },
    fromWhatCity:{
        en: "From what city",
        ru: "Из какого города",
        am: "որ քաղաքից",
        pl: "Z jakiego miasta"
    },
    toWhichCity:{
        en: "To which city",
        ru: "В какой город",
        am: "որ քաղաք",
        pl: "Do jakiego miasta"
    },
    selectProduct:{
        en: "Select product",
        ru: "Выберите товар",
        am: "Ընտրել ապրանքը",
        pl: "Wybierz produkt"
    },
    transportationWillCost:{
        en: "transportation will cost",
        ru: "перевозка бдет стоить",
        am: "տեղափոխումը կարժենա",
        pl: "transport będzie kosztować"
    },
    result:{
        en: "Result",
        ru: "Результат",
        am: "Արդյունք",
        pl: "Wynik"
    },
    
    packagesList:{
        en: "Packages List",
        ru: "Список пакетов",
        am: "Փաթեթների ցուցակ",
        pl: "Lista pakietów"
    },
    services:{
        en: "Services",
        ru: "Услуги",
        am: "Ծառայություններ",
        pl: "Usługi"
    },
    serviceName:{
        en: "Service name",
        ru: "Название услуги",
        am: "Ծառայության անուն",
        pl: "Nazwa serwisu"
    },
    price: {
        en: "Price",
        ru: "Цена",
        am: "Արժեք",
        pl: "Cena"
    },
    ourServices: {
        en: "Our Services",
        ru: "Наши шуслуги",
        am: "Մեր Ծառայությունները",
        pl: "Nasze Usługi"
    },
    additional_expenses: {
        en: "additional expenses",
        ru: "дополнительные расходы",
        am: "լրացուցիչ ծախսեր",
        pl: "dodatkowe wydatki"
    },

                                                /* taxi */
    

    /* taxi cars title */
    selectACar:{
        en: "Select a car",
        ru: "Выберите Машину",
        am: "Ընտրել մեքենա",
        pl: "Wybierz samochód"
    },

    requiredDocuments: {
        en: "Required documents",
        ru: "Необходимые документы",
        am: "Անհրաժեշտ փաստաթղթեր ",
        pl: "Wymagane dokumenty"
    },
    taxiBonusSystem: {
        en: "Bonus System",
        ru: "Бонусная Система",
        am: "Բոնուսային համակարգ",
        pl: "System bonusowy"
    },
    taxiRules:{
        en: "Rules",
        ru: "Правила",
        am: "Կանոններ",
        pl: "Zasady"
    },
    responsibilities:{
        en: "Responsibilities",
        ru: "Обязанности",
        am: "Պարտականություններ",
        pl: "Obowiązki"
    },
    carInfo:{
        en: "Information",
        ru: "Инфо8мация",
        am: "Տեղեկատվություն",
        pl: "Informacja"
    },
    transmission:{
        en: "Transmission",
        ru: "Коробка передач",
        am: "Փոխանցման Տուփ",
        pl: "Przenoszenie"
    },
    fuel:{
        en: "Fuel",
        ru: "Топливо",
        am: "վառելիք",
        pl: "Paliwo"
    },
    bac:{
        en: "Bac",
        ru: "Бак",
        am: "Բաք",
        pl: "Czołg"
    },
    advantagesc:{
        en: "Our Advantages",
        ru: "Наши Преимущества",
        am: "Մեր Առավելությունները",
        pl: "Nasze Atuty"
    },
    ourCars:{
        en: "Most of our cars are from 2020 and newer. Forget about old Skoda, Prius 2 and others.",
        ru: "Большинство наших автомобилей от 2020 выпуска и новее. Забудьте о стареньких Skoda, Prius 2 и других.",
        am: "Մեր մեքենաների մեծ մասը 2020 թվականից և ավելի նոր են: Մոռացեք հին Skoda-ի, Prius 2-ի և մյուսների մասին:",
        pl: "Większość naszych samochodów jest z roku 2020 i nowszych. Zapomnij o starej Skodzie, Priusie 2 i innych."
    },
    bAD:{
        en: "Rent",
        ru: "Арендовать",
        am: "Վարձակալել",
        pl: "Wynajem"
    },
    bacL:{
        en: "l",
        ru: "л",
        am: "լ",
        pl: "l"
    },
    BecomATaxiDriverInfo:{
        en: "write your name and number and we will call you within 30 minutes",
        ru: "напишите ваше име и номер и мыпазвоним вам в течении 30 минут",
        am: "գրեք ձեր անունը և համարը և մենք կզանգահարենք ձեզ 30 րոպեի ընթացքում",
        pl: "wpisz swoje imię i nazwisko oraz numer, a my oddzwonimy do Ciebie w ciągu 30 minut"
    }

}