import React from 'react'
import Wrapper from '../component/Wrapper'
import VacanciesDetalesArea from "../component/vacancies-detales-area"
import VDContacts from '../component/vdContacts'

function VacanciesDetales(){
    return(
        <Wrapper helmetTitle={"Vacancies"}>
            <VacanciesDetalesArea/>
            <VDContacts/>
        </Wrapper>
    )
}

export default VacanciesDetales