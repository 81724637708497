export class Account {
  static setToken(token) {
    localStorage.setItem('token', token);
  }

  static getToken() {
    return localStorage.getItem('token') || null;
  }
  static setLanguage(lang) {
    localStorage.setItem('language', lang);
  }

  static getLanguage() {
    return localStorage.getItem('language') || "en";
  }

  static deleteStrong() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }

  static getUser() {
    try {
      return JSON.parse(localStorage.getItem('user')) || {};
    } catch (e) {
      return {};
    }
  }

  static setUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  static sendProfileStrong(profile) {
    localStorage.setItem('profile', JSON.stringify(profile))
}

static sendTokenStrong(token) {
    localStorage.setItem('token', token)
}

static getProfileStrong() {
    try {
        const profileString = localStorage.getItem('profile');
        return JSON.parse(profileString)
    } catch (e) {
        return {}
    }
}

static getTokenStrong() {
    try {
        return localStorage.getItem('token')
    } catch (e) {
        return null
    }
}

static removeStrong() {
    localStorage.removeItem('profile')
    localStorage.removeItem('token')
}

static getNavbarUrlPath() {
    try {
        const navPath = localStorage.getItem('nav_url_path_name');
        const subMenuPath = localStorage.getItem('nav_sub_menu_url_path_name');
        return {
            navPath,
            subMenuPath
        }
    } catch (e) {
        return null
    }
}

static removeNavbarUrlPath() {

    localStorage.removeItem('nav_url_path_name');
    localStorage.removeItem('nav_sub_menu_url_path_name');

}

static setNavbarUrlPath(path) {
    localStorage.setItem('nav_url_path_name', path)
}

static setNavbarUrlPathSub(path) {
    localStorage.setItem('nav_sub_menu_url_path_name', path)
}
}